import React, { useState } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { useMediaQuery } from "react-responsive"
import TitleBanner from "../components/titleBanner/TitleBanner"
import Filter from "../components/filter/Filter"
import CardsSliders from "../components/common/cards-sliders/CardsSliders"
import CardsList from "../components/common/cardsList/CardsList"

let Certifications = ({ data }) => {
  const [isFilterOpen, setisFilterOpen] = useState(false)

  const [isMobileFilterOpen, setisMobileFilterOpen] =
    useState(false)

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  })

  const handleFilterClick = () => {
    setisFilterOpen(true)

    if (isMobile) {
      setisMobileFilterOpen(true)
    }
  }
  const closeFilter = () => {
    setisMobileFilterOpen(false)
  }

  const { courses } = data

  return (
    <>
      <SEO
        title="Ace Industrial Academy | Certified Professional Certifications"
        description="As a Autodesk Authorized Training Center, Bentley Qualified Training Partner, and Pearson Vue Authorized Test Center, we are the leading BIM & CAD certification center based in Singapore."
      />

      <TitleBanner
        title="Certifications"
        description="We conduct trainings with BIM Courses for AutoCad, Revit, ProjectWise360, BIM360 and many more"
        handleFilterClick={handleFilterClick}
        isFilterOpen={isFilterOpen}
      />
      {isFilterOpen ? (
        <div className="coursesList">
          {isMobile ? (
            <>
              {isMobileFilterOpen && (
                <Filter closeFilter={closeFilter} />
              )}
            </>
          ) : (
            <Filter />
          )}

          <CardsList isCertificate />
        </div>
      ) : (
        <CardsSliders
          courses={courses.nodes}
          isCertificate
        />
      )}
    </>
  )
}

export const query = graphql`
  query {
    courses: allContentfulTraining {
      nodes {
        id
        category
        title
        courseReference
        trainingMode
        updatedAt
        slug
        skillsFramework
        requirements
        lessons
        duration
        product {
          id
          name
          netFee
          price
        }
        coverPhoto {
          gatsbyImageData
        }
      }
    }
  }
`
export default Certifications
